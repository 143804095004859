.section-dependencies {
    text-align: center;

    h1 {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 60px;
        margin-bottom: 30px;
        text-align: center;
      }
}